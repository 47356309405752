import PropTypes from "prop-types";
import React, { Component } from "react";
import Logo from "../assets/images/logo.png";
import Landscape from "../assets/images/cambridge.png";

import "../assets/styles/components/header.css";

class Header extends Component {
  render() {
    return (
      <header id="site-header">
        <div className="logo">
          <figure className="logo__image">
            <a href="/" aria-label="Back to Homepage" title="Back to Homepage">
              <img
                src={Logo}
                alt={this.props.siteTitle}
              />
            </a>
          </figure>
          <figure className="logo__landscape">
            <img
              src={Landscape}
              alt={this.props.siteTitle}
            />
          </figure>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
